import { PlanCodes, SexAtBirth } from 'utils/enums';

import { ParamsToDefineFlowSteps, PossibleStepsType, StepName } from './postOnboarding.types';
export const MIF_QUESTIONS: StepName[] = [
  'medications',
  'allergies',
  'women-health',
  'chronic-diseases',
  'social-history',
  'health-conditions',
  'mif-summary',
  'pharmacy'
];

export const defineFlowSteps = (
  steps: PossibleStepsType,
  params: ParamsToDefineFlowSteps
): StepName[] => {
  let copySteps: StepName[] = [...steps];
  const stepsToFilter: StepName[] = [];

  // Check different conditions to filter steps by adding steps to filter array in the end

  // 1. Right now we always remove pre-confirmation step. Find out if we might need it in the future
  stepsToFilter.push('pre-confirmation');

  // 2. If user has address, we remove address step
  if (params.address) {
    stepsToFilter.push('address');
  }

  // 3. If user has insurance or selected 'no', we remove pre-insurance and insurance steps
  if (
    params.isAsyncPlan ||
    (params.hasInsurance && params.insurances.length > 0) ||
    params.hasInsurance === false ||
    params.activePlanCode !== PlanCodes.WeightManagementMembership
  ) {
    stepsToFilter.push('pre-insurance', 'insurance');
  }

  // 4. If user is verified by Vouched or doesn't have an active plan (means he is freemium user), we remove all steps related to identity verification
  if (params.identityVerified || !params.activePlanCode) {
    stepsToFilter.push('verify-identity');
  }

  // 5. If user has completed medical intake, we remove all steps related to medical intake
  if (params.medicalIntakeCompleted) {
    stepsToFilter.push(...MIF_QUESTIONS);
  }
  // 6. For not a female Sex at Birth hide step with women health
  if (params.sexAtBirth !== SexAtBirth.Female) {
    stepsToFilter.push('women-health');
  }

  // 7. If user is not from FL or NJ we remove body photo step

  if (!['Florida', 'New Jersey'].includes(params.state) || !params.bodyImage) {
    stepsToFilter.push('upload-body-photo');
  }

  // 8. If user is not a Kroger patient, remove kroger connect id step
  // if (!params.isKrogerPatient || params.isKrogerIDConnected) {
  //   stepsToFilter.push('kroger');
  // }

  copySteps = copySteps.filter((step) => !stepsToFilter.includes(step));

  return copySteps;
};
