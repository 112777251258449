import { SexAtBirth } from 'utils/enums';

import { InsuranceItem } from 'models/insurance.types';

export const POST_ONBOARDING_STEPS = [
  'pre-confirmation',
  'address',
  'pre-insurance',
  'insurance',
  'verify-identity',
  'medications',
  'allergies',
  'health-conditions',
  'chronic-diseases',
  'women-health',
  'social-history',
  'upload-body-photo',
  'mif-summary',
  'pharmacy'
  // 'kroger' - we hide it for v1
] as const;

export type PossibleStepsType = typeof POST_ONBOARDING_STEPS;
export type StepName = PossibleStepsType[number];

export type ParamsToDefineFlowSteps = {
  activePlanCode: string;
  address: string;
  bodyImage: boolean;
  dob: string;
  hasInsurance: boolean | null;
  identityVerified: boolean;
  insurances: InsuranceItem[];
  isAsyncPlan?: boolean;
  isFirstAppointmentCompleted: boolean;
  isHRTPatient?: boolean;
  isKrogerIDConnected: boolean;
  isKrogerPatient: boolean;
  medicalIntakeCompleted: boolean;
  sexAtBirth: SexAtBirth | '';
  state: string;
};
